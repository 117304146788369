module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KBFLQHF","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gwpt-packages-light/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://cms.myfutureplan.co.nz","pathPrefix":"","paginationPrefix":"page","socialFollowLinks":[],"overrideWPColors":false,"developLimit":null,"addColorModes":false,"webfontsOptions":{"fonts":{"google":[{"family":"Montserrat","variants":["100","300","400","700","800"]}]}},"gatsbySourceWordPressOptions":{"html":{"createStaticFiles":true}},"createPages":true,"createPosts":true,"createCategories":true,"createTags":true,"createUsers":true,"withApollo":true,"pageCreateDebugOutput":true,"addWordPressComments":false,"addWordPressSearch":false,"fonts":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.myfutureplan.co.nz/graphql","excludeFieldNames":["blocksJSON","saveContent"],"presets":[{"presetName":"DEVELOP","options":{"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true}}],"html":{"createStaticFiles":true,"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-theme-blog-data-light/gatsby-browser.js'),
      options: {"plugins":[],"webfontsOptions":{"fonts":{"google":[{"family":"Montserrat","variants":["100","300","400","700","800"]}]}},"gatsbySourceWordPressOptions":{"html":{"createStaticFiles":true}},"pageCreateDebugOutput":true,"addWordPressComments":false,"addWordPressSearch":false,"addColorModes":false,"wordPressUrl":"https://cms.myfutureplan.co.nz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.myfutureplan.co.nz","noTrailingSlash":false,"noHash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MyFuture Plan","short_name":"MyFuture Plan","start_url":"/","background_color":"#249DD6","theme_color":"#249DD6","display":"standalone","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"810dc6337e1fc06aa746751c7e6f9f10"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
